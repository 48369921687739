import axios from 'axios'

axios.defaults.withCredentials = true

const client = axios.create({
  baseURL: 'https://staging.ohanasi-jpschool-members.com/' || 'http://localhost:8000',
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
})

export default client
